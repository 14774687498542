







































































































































































































import { Vue, Component } from "vue-property-decorator";
import Quill from "quill";
import { VueEditor } from "vue2-editor";
import htmlEditButton from "quill-html-edit-button";
import { convertToNumber } from "@/helpers/tools";
import { Course } from "@/types";
import axios, { AxiosRequestHeaders } from "axios";

Quill.register("modules/htmlEditButton", htmlEditButton);

@Component({
  metaInfo() {
    return {
      title: "Oluştur / Eğitimler / Admin"
    };
  },
  components: {
    VueEditor
  }
})
export default class AdminCourseCreateView extends Vue {
  form = {
    name: "",
    code: null as string | null,
    urlCode: "",
    price: "0",
    startDate: null as string | null, // new Date().toISOString().substr(0, 10),
    finishDate: null as string | null,
    shortDescription: null as string | null,
    description: null as string | null,
    image: null as File | null,
    type: null as string | null,
    duration: null as string | null,
    location: null as string | null,
    minParticipant: null as number | null,
    maxParticipant: null as number | null,
    disabled: false,
    closedToRegistration: false
  };
  startDatePicker = false;
  finishDatePicker = false;
  submitDisabled = false;
  previewImgUrl = null as string | null;
  previewImgLoading = false;
  editorOptions = {
    modules: {
      htmlEditButton: {
        syntax: true
      }
    }
  };

  courseType = ["Eğitmen Liderliğinde", "Öz Tempolu"];
  courseLocation = ["Sınıf", "Çevrimiçi"];

  get requiredRule(): unknown[] {
    return [(v: unknown) => !!v || "Zorunlu alan"];
  }

  get urlCodeRules(): unknown[] {
    return [(v: string) => !!v || "Zorunlu alan", (v: string) => /^[a-z0-9-]*$/.test(v) || "Geçersiz URL kodu"];
  }

  get previewImgRules(): unknown[] {
    return [
      // (v: File) => !!v || "Zorunlu alan",
      (v: File) => !v || v.size < 3145728 || "Dosya boyutu 3 MB'den küçük olmalıdır."
    ];
  }

  get priceRules(): unknown[] {
    return [
      (v: string) => !!v || "Zorunlu alan",
      (v: string) =>
        !v ||
        /^((?=.*[1-9]|0)(?:\d{1,3}))((?=.*\d)(?:\.\d{3})?)*((?=.*\d)(?:,\d\d){1}?){0,1}$/gm.test(v) ||
        "Geçersiz fiyat. Ör: 1.000 ya da 1.000,00"
    ];
  }

  resetForm() {
    const form = this.$refs.form as HTMLFormElement;
    form.reset();

    this.form.shortDescription = "";
    this.form.description = "";
    this.resetValidation();
  }

  resetValidation() {
    const form = this.$refs.form as HTMLFormElement;
    form.resetValidation();
  }

  onPreviewImgChange() {
    this.previewImgUrl = this.form.image ? URL.createObjectURL(this.form.image) : null;
  }

  async create() {
    const form = this.$refs.form as HTMLFormElement;

    if (!form.validate()) {
      return;
    }

    this.submitDisabled = true;

    this.$notify({
      text: "Eğitim oluşturuluyor. Lütfen bekleyin..."
    });

    try {
      const formData = new FormData();

      formData.append("name", this.form.name);
      formData.append("urlCode", this.form.urlCode);
      formData.append("price", convertToNumber(this.form.price).toString());
      formData.append("disabled", this.form.disabled.toString());
      formData.append("closedToRegistration", this.form.closedToRegistration.toString());
      if (this.form.code) formData.append("code", this.form.code);
      if (this.form.startDate) formData.append("startDate", this.form.startDate);
      if (this.form.finishDate) formData.append("finishDate", this.form.finishDate);
      if (this.form.shortDescription) formData.append("shortDescription", this.form.shortDescription);
      if (this.form.description) formData.append("description", this.form.description);
      if (this.form.image) formData.append("image", this.form.image);
      if (this.form.type) formData.append("type", this.form.type);
      if (this.form.duration) formData.append("duration", this.form.duration);
      if (this.form.location) formData.append("location", this.form.location);
      if (this.form.minParticipant) formData.append("minParticipant", this.form.minParticipant.toString());
      if (this.form.maxParticipant) formData.append("maxParticipant", this.form.maxParticipant.toString());

      const authHeader = this.$store.getters["auth/requestAuthHeader"];
      const contentTypeHeader = { "Content-Type": "multipart/form-data" };

      // Call add course api
      var res = await axios.post("/api/course/create", formData, {
        headers: Object.assign(authHeader, contentTypeHeader)
      });

      this.$notify({
        type: "success",
        title: "İşlem Tamam",
        text: "Eğitim başarıyla oluşturuldu."
      });

      // After 2 second return to courses page
      const timer1 = setInterval(() => {
        this.$router.push({ name: "AdminCourses" });

        clearInterval(timer1);
      }, 2000);
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Eğitim oluşturulamadı.`
      });

      throw e;
    } finally {
      this.submitDisabled = false;
    }
  }
}
